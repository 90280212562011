@media screen{
  .app-article-print{ display: none; }
  .print{ display: none; }
}

.ui-article-print{
  display: none;
}

@media print{

  .ui-article-print{
    display: block;
  }

  .isPrinting *{
    display: none;
  }
  .isPrinting{
    .app-article-print{ display: block; }
    .app-article-print *{ display: block; }
  }
  app-header, footer{
    display: none;
  }
  *{
    color: black !important;
    background-color: white!important;
  }
  .ui-loan *{
    font-size: 16pt !important;
  }
  @page footer{
    display: none!important
  }
  @page header{
    display: none!important
  }
  @page{
    size: 330mm 427mm;
    margin: 10mm 0;
  }
  .print{
    page-break-after: always;
  }
  .print thead{
    display: table-header-group;
  }
  .print tfoot{
    display: table-footer-group;
  }

  .print .print-article{
    page-break-inside: avoid;
  }
}
