@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/grid";

@media all and (max-width: 320px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  border-top-color: #309CFF;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//*{
//  outline: 1px solid red;
//}

.module-type-b {
  display: grid;
  grid-template-columns: repeat(24, minmax(0, 1fr));
  gap: 1rem;
  padding: 80px 0 32px;
  margin: 0 auto;
  max-width: 1136px;
  width: 100%;

  .module-wrapper {
    grid-column: span 24 / span 24;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media all and (min-width: 768px) {
  .module-type-b {
    padding: 80px 0;

    .module-wrapper {
      grid-column: span 8 / span 8;
    }
  }
}

@media all and (min-width: 1136px) {
  .module-type-b {
    .module-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}


.slot-23-desc {
  color: #717171;
  font-size: 16px;
  line-height: 20px;

  .slot-a2x-description {
    margin-bottom: 32px;
    padding-bottom: 24px;
    display: block;
    width: 100%;
    position: relative;

    &:after {
      content: '';
      width: 80px;
      height: 8px;
      background: #A8772D;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  a {
    position: relative;
    display: block;
    margin-bottom: 32px;
    padding-left: 40px;
    font-size: 18px;
    font-weight: 700;
    color: #424242;

    &:before {
      content: url("data:image/svg+xml,%3Csvg width='24' height='22' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.7712 11.5796L14.8831 21.0088C14.7063 21.1963 14.417 21.2874 14.2242 21.2874C14.0106 21.2874 13.7962 21.2138 13.6263 21.0648C13.2634 20.7466 13.2365 20.2043 13.5664 19.8545L21.1032 11.8582H0.88881C0.397473 11.8582 0 11.4749 0 11.0492C0 10.6236 0.39758 10.1438 0.88881 10.1438H21.1027L13.5647 2.14505C13.2348 1.79516 13.2617 1.25249 13.6246 0.934795C13.9883 0.618381 14.5498 0.641793 14.8809 0.993406L23.769 10.4226C24.0766 10.7492 24.0766 11.2528 23.7712 11.5796Z' fill='%23A8772D'/%3E%3C/svg%3E%0A");
      position: absolute;
      left: 0;
      top: 2px;
    }

    &:hover {
      color: #424242;
      text-decoration: underline;
    }
  }
}

.highlighted-module .slot-23-desc {
  color: #FFFFFF;

   a {
    position: relative;
    display: block;
    margin-bottom: 32px;
    padding-left: 40px;
    font-size: 18px;
    font-weight: 700;
    color: #FFFFFF;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media all and (min-width: 768px) {
  .slot-23-desc {
    font-size: 18px;
    line-height: 28px;
  }
}

.module-130-wrapper {
  height: 518px;
}

.slot-a30-for {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: calc(100% - 4rem);
}

.slot-a30 {
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 2rem;
  }
}

.bg-gradient-hero {
  background: linear-gradient(0deg, rgba(8, 15, 26, 0.3), rgba(8, 15, 26, 0.3));
}

//Swiper
.swiper-module-a60,
.swiper-module-a61,
.swiper-faq-loans,
.swiper-module-a62 {
  .swiper-slide {
    height: auto !important;
    align-self: stretch;
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -5px !important;
}

app-content-module-a010 {
  .swiper-pagination-bullet {
    transition: all ease-in 0.25s;
    background: #fff;

    &:hover {
      opacity: 1;
    }
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
}

.highlighted-module .swiper-pagination-bullet {
  background-color: #fff;
}

.swiper-pagination-bullet {
  transition: all ease-in 0.25s;
  background: #424242;

  &:hover {
    opacity: 1;
  }
}

.swiper-pagination-bullet-active {
  background: #424242;
}


.tooltip-example {
  text-align: center;
  padding: 0 50px;
}

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 4px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 240px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: #282a36 transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent #282a36 transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent #282a36;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent #282a36 transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}


.animate-element {
  animation: placeholderAnimate 2s ease-in-out 0s infinite reverse;
  -webkit-animation: placeholderAnimate 2s ease-in-out 0s infinite reverse;
}

@keyframes placeholderAnimate {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

@-webkit-keyframes placeholderAnimate {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.border-gradient-gold {
  position: relative;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.border-gradient-gold::before {
  content: "";
  position: absolute;
  inset: -4px; /* Matches border width */
  border-radius: inherit; /* Inherits the border-radius */
  padding: 4px; /* Matches border width */
  background: linear-gradient(to right, #AA7931, #F4CA7B); /* Gradient border */
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.border-gradient-gold-card::before {
  content: "";
  position: absolute;
  inset: -2px; /* Matches border width */
  border-radius: inherit; /* Inherits the border-radius */
  padding: 2px; /* Matches border width */
  background: linear-gradient(to right, #AA7931, #F4CA7B); /* Gradient border */
  -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}


.border-gradient-gold-card:hover::before,.border-gradient-gold:hover::before {
  opacity: 1; /* Show the gradient border on hover */
}

.gradient-icon  {
  color: #AA7931;
  display: block;
  background: -webkit-linear-gradient(to right, #AA7931, #F4CA7B);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

